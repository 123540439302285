<template>
  <section class="todoapp">
   
    <header>
      <div style="padding-top:30px;background:#dce4f1" class="progress-item">
        <div style="margin-top: -5px"><span style="color:#000000">消息</span></div>
      </div>
        <!-- <input class="new-todo"  style="height:60px" autocomplete="off" placeholder="消息" @keyup.enter="addTodo"> -->
    </header>
  
    <section v-show="todos.length" class="main" style="height: 357px;background:#FFFFFF">
      <input id="toggle-all" :checked="allChecked" class="toggle-all" type="checkbox" @change="toggleAll({ done: !allChecked })">
      <label for="toggle-all" />
      <ul class="todo-list">
          <todo
            v-for="(todo, index) in filteredTodos"
            :key="index"
            :todo="todo"
            :style="todo.done == false? 'font-weight: bold;color: #000000': ''"
            @toggleTodo="toggleTodo"
            @editTodo="editTodo"
            @deleteTodo="deleteTodo"
          />
      </ul>
    </section>
  
    <footer v-show="todos.length" class="footer" style="margin-top:20px;" >
      <span class="todo-count">
        <strong>{{ remaining }}</strong>
        {{ remaining | pluralize('消息') }}
      </span>
      <ul class="filters">
        <li v-for="(val, key) in filters" :key="key">
          <a :class="{ selected: visibility === key }" @click.prevent="visibility = key">{{ key | capitalize }}</a>
        </li>
      </ul>
     
    </footer>
  </section>
</template>

<script>
import Todo from './Todo.vue'
import {QueryMessageListByUserCode} from "../../../../../api/oa"
const STORAGE_KEY = 'todos'
const filters = {
  '全部': todos => todos,
  '未读': todos => todos.filter(todo => !todo.done),
  '已读': todos => todos.filter(todo => todo.done)
}
// const defalutList = []
export default {
  components: { Todo },
  filters: {
    pluralize: (n, w) => n === 1 ? w : w ,
    capitalize: s => s.charAt(0).toUpperCase() + s.slice(1)
  },
  data() {
    return {
      visibility: '全部',
      filters,
      todos: [
        {text:'欢迎使用工作任务系统',done: false},
        {text:'我的日常工作002正在进行中',done: false},
        {text:'收到了一条消息推送',done: false},
        {text:'我的专项会议工作正在进行中',done: true},
        {text:'管理员可进行权限分配',done: true},
        {text:'我的例会项目审核通过',done: true},
        {text:'例会项目正在进行中',done: true},
        {text:'请及时点击我的待阅信息',done: true},

      ],
      showtext: null
      // todos: defalutList
    }
  },
  computed: {
    allChecked() {
      return this.todos.every(todo => todo.done)
    },
    filteredTodos() {
      return filters[this.visibility](this.todos)
    },
    remaining() {
      return this.todos.filter(todo => !todo.done).length
    }
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews(){
        var ucode = JSON.parse(window.localStorage.user);
        QueryMessageListByUserCode({userCode: ucode.uID}).then((res) => {
                res.data.response.map(item => {
                  let obj = {}
                  obj.text = item['Title']
                  if(item['ReadComplated'] == 0){
                      obj.done = false;
                  }else{
                    obj.done = true;
                  }
                  this.todos.push(obj)
            })
        });
    },
    setLocalStorage() {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.todos))
    },
    addTodo(e) {
      const text = e.target.value
      if (text.trim()) {
        this.todos.push({
          text,
          done: false
        })
        this.setLocalStorage()
      }
      e.target.value = ''
    },
    toggleTodo(val) {
      val.done = !val.done;
      this.setLocalStorage()
    },
     mouseover(todo){
       console.info(todo);
      // if(todo.text.length > 18){
      //     this.showtext = todo.text;
      // }
    },
    deleteTodo(todo) {
      this.todos.splice(this.todos.indexOf(todo), 1)
      this.setLocalStorage()
    },
    editTodo({ todo, value }) {
      todo.text = value
      this.setLocalStorage()
    },
    clearCompleted() {
      this.todos = this.todos.filter(todo => !todo.done)
      this.setLocalStorage()
    },
    toggleAll({ done }) {
      this.todos.forEach(todo => {
        todo.done = done
        this.setLocalStorage()
      })
    }
  }
}
</script>

<style lang="scss">
  @import './index.scss';
  .progress-item {
    margin-bottom: 11px;
    // margin-left: 20px;
    font-size: 17px;
  }
</style>
